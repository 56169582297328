import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import RichText from './richtext';

import CircleStarCircleBlack from '../static/assets/circle-star-circle-black.svg';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Footer {
      header: allSanityHeader {
        edges {
          node {
            title
            _rawSubtitle
            socialMedia {
              behance
              behanceLink
              facebook
              facebookLink
              instagram
              instagramLink
              twitter
              twitterLink
            }
          }
        }
      }
      footer: allSanityFooter {
        edges {
          node {
            _rawText
          }
        }
      }
    }
  `);

  const header = data.header.edges.map(edge => edge.node);
  const footer = data.footer.edges.map(edge => edge.node);

  return (
    <StyledFooter className="section fp-auto-height light">
      <div className="inner">
        <div className="icons">
          <CircleStarCircleBlack />
          <br />
          <CircleStarCircleBlack />
        </div>
        <div className="kr8">
          <p>{header[0].title}</p>
          <p>
            BUREAU FÜR
            <br />
            BRAND IDENTITY
            <br />
            STRATEGIE +<br />
            DESIGN
          </p>
        </div>
        <div className="social">
          <ul>
            <a
              href={header[0].socialMedia.behanceLink}
              target="_blank"
              rel="noreferrer"
            >
              <li>Behance</li>
            </a>
            <a
              href={header[0].socialMedia.facebookLink}
              target="_blank"
              rel="noreferrer"
            >
              <li>Facebook</li>
            </a>
            <a
              href={header[0].socialMedia.instagramLink}
              target="_blank"
              rel="noreferrer"
            >
              <li>Instagram</li>
            </a>
            <a
              href={header[0].socialMedia.linkedinLink}
              target="_blank"
              rel="noreferrer"
            >
              <li>Linkedin</li>
            </a>
            <a
              href={header[0].socialMedia.twitterLink}
              target="_blank"
              rel="noreferrer"
            >
              <li>Twitter</li>
            </a>
          </ul>
        </div>
        <div className="legal">
          <div>
            <Link to="/impress">
              <p>Impress</p>
            </Link>
            <Link to="/contact">
              <p>Contact</p>
            </Link>
          </div>
          <div className="desc">
            <RichText blocks={footer[0]._rawText} />
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  min-height: var(--spacing-XXL);
  border-top: 1px solid var(--text-color);
  text-transform: uppercase;

  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
    gap: var(--spacing-XXS);

    .icons {
      grid-column: 1 / 2;
    }

    .kr8 {
      text-align: right;
      grid-column: 3 / 5;
    }

    .social {
      text-align: center;
      grid-column: 6 / 8;
    }

    .legal {
      grid-column: 9 / 12;
      .desc {
        padding-top: var(--spacing-S);
      }
    }

    @media (max-width: 1020px) {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-S) var(--spacing-XXS);

      .kr8,
      .social,
      .legal {
        padding-bottom: var(--spacing-S);
      }

      .icons {
        display: none;
      }

      .kr8 {
        text-align: left;
      }

      .social {
        text-align: left;
      }
    }
  }
`;

export default Footer;
