/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ReactFullpage from '@fullpage/react-fullpage';
import styled from 'styled-components';

import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import localize from '../components/localize';
import RichText from '../components/richtext';
import Footer from '../components/footer';
import { useEventListener } from '../hooks';

const StudioPage = ({ data }) => {
  const studio = data.studio.edges.map(edge => edge.node);

  const [activeSection, setActiveSection] = useState();
  const afterLoad = (origin, destination) => {
    setActiveSection(destination.index);
  };

  const [activePerson, setActivePerson] = useState(0);

  const [showImg, setShowImg] = useState(false);
  const [posImg, setPosImg] = useState({});

  const handleCursor = e => {
    setPosImg({ x: e.pageX, y: e.pageY });
  };
  useEventListener('click', handleCursor);

  const clientsLeft = studio[0].clients.filter((el, i) => i % 2 === 0);
  const clientsRight = studio[0].clients.filter((el, i) => i % 2 !== 0);

  return (
    <>
      <Layout
        current={studio[0].title}
        dePath="/studio"
        enPath="/en/studio"
        hideHeader={activeSection !== 0}
        hideNav={activeSection === 3}
        disableUp={activeSection === 0}
        disableDown={activeSection === 2}
        onClickUp={() => window.fullpage_api.moveSectionUp()}
        onClickDown={() => window.fullpage_api.moveSectionDown()}
      >
        <SEO siteTitle={studio[0].title} />
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE}
          responsiveWidth={1020}
          scrollOverflow
          autoScrolling
          scrollingSpeed={600}
          easingcss3="cubic-bezier(0.700, 0.000, 1.000, 1.000)"
          afterLoad={afterLoad}
          render={() => (
            <div id="fullpage-wrapper">
              <StyledSectionOne className="section light">
                <div className="inner">
                  <div className="big centered">
                    <RichText blocks={studio[0]._rawDescription} />
                  </div>
                </div>
              </StyledSectionOne>
              <StyledSectionTwo className="section light fp-auto-height-responsive">
                <div className="inner">
                  <div className="headline big">
                    <div className="right-aligned left">
                      {showImg && (
                        <div
                          className="img-wrapper"
                          style={{ top: posImg.y, left: posImg.x }}
                        >
                          <Img
                            className="img"
                            fluid={
                              studio[0].people[activePerson]?.mainImage.image
                                .asset.fluid
                            }
                            alt={studio[0].people[activePerson]?.mainImage.alt}
                          />
                        </div>
                      )}
                      <button
                        type="button"
                        onClick={() => {
                          setActivePerson(0);
                          setShowImg(true);
                        }}
                        onMouseLeave={() => setShowImg(false)}
                      >
                        <p className="big">{studio[0].people[0]?.who}</p>
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setActivePerson(1);
                          setShowImg(true);
                        }}
                        onMouseLeave={() => setShowImg(false)}
                      >
                        <p className="big">{studio[0].people[1]?.who}</p>
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setActivePerson(2);
                          setShowImg(true);
                        }}
                        onMouseLeave={() => setShowImg(false)}
                      >
                        <p className="big">{studio[0].people[2]?.who}</p>
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setActivePerson(3);
                          setShowImg(true);
                        }}
                        onMouseLeave={() => setShowImg(false)}
                      >
                        <p className="big">{studio[0].people[3]?.who}</p>
                      </button>
                    </div>
                    <div className="right">
                      <button
                        type="button"
                        onClick={() => {
                          setActivePerson(4);
                          setShowImg(true);
                        }}
                        onMouseLeave={() => setShowImg(false)}
                      >
                        <p className="big">{studio[0].people[4]?.who}</p>
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setActivePerson(5);
                          setShowImg(true);
                        }}
                        onMouseLeave={() => setShowImg(false)}
                      >
                        <p className="big">{studio[0].people[5]?.who}</p>
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setActivePerson(6);
                          setShowImg(true);
                        }}
                        onMouseLeave={() => setShowImg(false)}
                      >
                        <p className="big">{studio[0].people[6]?.who}</p>
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setActivePerson(7);
                          setShowImg(true);
                        }}
                        onMouseLeave={() => setShowImg(false)}
                      >
                        <p className="big">{studio[0].people[7]?.who}</p>
                      </button>
                    </div>
                  </div>
                  <div className="description">
                    <div className="desc-left right-aligned">
                      <RichText
                        blocks={studio[0].people[activePerson]?._rawWhat}
                      />
                    </div>
                    <div className="desc-right">
                      <RichText
                        blocks={studio[0].people[activePerson]?._rawDescription}
                      />
                    </div>
                  </div>
                </div>
              </StyledSectionTwo>
              <StyledSectionThree className="section dark fp-auto-height-responsive">
                <div className="inner">
                  <h2 className="big centered">Recognition</h2>
                  <div className="awards">
                    <h3 className="big right-aligned left">Awards</h3>
                    <div className="right">
                      <div className="content">
                        {studio[0].awards.map((award, i) => (
                          <div key={i} className="content-pair">
                            <div className="content-left">
                              <RichText blocks={award._rawHeadline} />
                            </div>
                            <div className="content-right">
                              <RichText blocks={award._rawText} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="publications">
                    <h3 className="big right-aligned left">Publications</h3>
                    <div className="right">
                      <div className="content">
                        {studio[0].publications.map((publication, i) => (
                          <div key={i} className="content-pair">
                            <div className="content-left">
                              <RichText blocks={publication._rawHeadline} />
                            </div>
                            <div className="content-right">
                              <RichText blocks={publication._rawText} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="features">
                    <h3 className="big right-aligned left">Features</h3>
                    <div className="right">
                      <div className="content">
                        {studio[0].features.map((feature, i) => (
                          <div key={i} className="content-pair">
                            <div className="content-left">
                              <RichText blocks={feature._rawHeadline} />
                            </div>
                            <div className="content-right">
                              <RichText blocks={feature._rawText} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <h2
                    className="big centered headline"
                    style={{ paddingTop: 'var(--spacing-L)' }}
                  >
                    Clients
                  </h2>
                  <div className="clients big">
                    <div className="clients-left right-aligned big">
                      {clientsLeft.map((client, i) => (
                        <p key={i}>{client}</p>
                      ))}
                    </div>
                    <div className="clients-right">
                      {clientsRight.map((client, i) => (
                        <p key={i}>{client}</p>
                      ))}
                    </div>
                    <div className="big centered clients-mobile">
                      {studio[0].clients.map((client, i) => (
                        <p key={i}>{client}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </StyledSectionThree>
              <Footer />
            </div>
          )}
        />
      </Layout>
    </>
  );
};

const StyledSectionOne = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    height: 95%;
    display: flex;
    align-items: center;
  }
`;

const StyledSectionTwo = styled.section`
  .inner {
    padding: var(--spacing-L) var(--spacing-XS) var(--spacing-S)
      var(--spacing-XS);

    .img-wrapper {
      position: absolute;
      pointer-events: none;
      .img {
        width: var(--spacing-XXL);
        pointer-events: none;
      }
    }

    .headline,
    .description {
      display: flex;
    }

    .description {
      padding-top: var(--spacing-L);
    }

    .left,
    .right,
    .desc-left,
    .desc-right {
      width: 50%;
      padding: 0 var(--spacing-XXS);
    }

    .right {
      p {
        text-align: left;
      }
    }

    .left {
      p {
        text-align: right;
      }
    }

    @media (max-width: 1020px) {
      padding: var(--spacing-S) var(--spacing-XXS);

      .headline,
      .description {
        flex-direction: column;
      }

      .description {
        padding-top: var(--spacing-S);
      }

      .left,
      .right,
      .desc-left,
      .desc-right {
        text-align: center;
        width: 100%;
        padding: 0;
      }

      .left,
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          text-align: center;
        }
      }

      .img-wrapper {
        display: none;
      }
    }
  }
`;

const StyledSectionThree = styled.section`
  .inner {
    padding: var(--spacing-S) var(--spacing-XS);
    width: 100%;

    .clients {
      display: flex;
      padding: var(--spacing-S) 0;

      .clients-left,
      .clients-right {
        width: 50%;
        padding: 0 var(--spacing-XXS);
      }

      .clients-right {
        text-align: left;
      }

      .clients-mobile {
        display: none;
      }
    }

    .awards,
    .publications,
    .features {
      padding-top: var(--spacing-S);
      width: 100%;
      display: flex;

      .content-pair {
        display: flex;

        .content-left {
          min-width: 20%;
        }
      }
    }

    .left,
    .right {
      width: 50%;
      padding: 0 var(--spacing-XXS);
    }

    @media (max-width: 1020px) {
      padding: var(--spacing-S) var(--spacing-XXS);

      .awards,
      .publications,
      .features {
        flex-direction: column;
      }

      .description {
        padding-top: var(--spacing-S);
      }

      .left,
      .right {
        width: 100%;
        padding: 0;
      }

      .left {
        text-align: center;
        padding-bottom: var(--spacing-XXS);
      }

      .clients {
        .clients-left,
        .clients-right {
          display: none;
        }

        .clients-mobile {
          display: block;
        }
      }
    }
  }
`;

export const query = graphql`
  query Studio {
    studio: allSanityStudio {
      edges {
        node {
          title
          _rawDescription
          people {
            who
            mainImage {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            _rawWhat
            _rawDescription
          }
          awards {
            _rawHeadline
            _rawText
          }
          publications {
            _rawHeadline
            _rawText
          }
          features {
            _rawHeadline
            _rawText
          }
          clients
        }
      }
    }
  }
`;

StudioPage.propTypes = {
  data: PropTypes.object,
};

export default localize(StudioPage);
